.ant-tabs-tab.ant-tabs-tab-active {
  .ant-tabs-tab-btn {
    text-shadow: none;
    color : #00ba75;
    font-weight: bolder;
    font-size: 16px;
  }
};

.ant-tabs-tab-btn :hover {
  color : black
}

.ant-tabs-tab {
  font-size: 16px;
}

.ant-tabs-tab :hover {
  color : black
}