.ant-message-custom-content .anticon {
  margin-right: 10px;
}

.ant-message-notice-content {
  border-radius: 2px;
}

.ant-message-notice .ant-message-custom-content span:nth-child(2) {
  font-weight: 300;
  color: @b-80;
}

.ant-message-notice .ant-message-loading svg {
  fill: @primary-1;
}

.ant-message-notice .ant-message-success svg {
  fill: @success-1;
}

.ant-message-notice .ant-message-info svg {
  fill: @info-1;
}

.ant-message-notice .ant-message-error svg {
  fill: @danger-1;
}

.ant-message-notice .ant-message-warning svg {
  fill: @warning-1;
}
