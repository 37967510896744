.ant-picker-focused {
  border-color: @primary-2;
  box-shadow: @input-focus;
}
.ant-picker {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0px;
  box-shadow: none;
}
