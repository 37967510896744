.@{class-prefix}-profile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid @b-40;
  min-height: 930px;

  &-header {
    .ant-scroll-number {
      bottom: -7px;
      right: 6px;
      top: auto;
      padding: 0 3px;
      height: 16px;
      line-height: 16px;
      z-index: 1;
      transform: translate(0px, 0px);
      box-shadow: none;
    }

    .ant-avatar {
      overflow: initial;

      &:before {
        content: "";
        position: absolute;
        top: -7px;
        left: -7px;
        right: -7px;
        bottom: -7px;
        background: conic-gradient(@primary-1, #fff0);
        border-radius: 100%;
        pointer-events: none;
      }

      &:after {
        content: "";
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: #ffffff;
        border-radius: 100%;
        pointer-events: none;
      }

      img {
        position: relative;
        z-index: 1;
      }
    }
  }

  &-body {
    margin-top: 50px !important;

    .is-active {
      .ant-row {
        color: @primary-1;
      }

      svg {
        fill: @primary-1;
      }
    }
  }

  @media @md {
    display: none;
  }
}

.@{class-prefix}-profile-content-list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      span {
        display: block;

        &:nth-child(1) {
          min-width: 200px;
        }
      }

      @media @sm {
        display: block;

        span {
          &:nth-child(1) {
            min-width: initial;
          }
        }
      }
    }
  }
}

.@{class-prefix}-profile-notifications {
  max-width: 424px;
}

.@{class-prefix}-profile-action-btn {
  @media @sm {
    order: 1;

    button {
      width: 100%;
      margin-top: 24px;
    }
  }
}

.@{class-prefix}-profile-connect-btn {
  flex: 0 0 360px;

  svg {
    min-width: 14px;
  }

  @media @sm {
    flex: 0 0 100%;
  }
}

.@{class-prefix}-profile-mobile-menu-btn {
  display: none;

  @media @md {
    display: block;
  }
}

.@{class-prefix}-profile-mobile-menu {
  .ant-drawer-header {
    padding: 16px;
    border: none;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .@{class-prefix}-profile-menu {
    padding: 0 0 35px !important;
    display: block;
    min-height: initial;
  }
}

.@{class-prefix}-profile-activity {
  .ant-table-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}