#market-place-chart {
  min-height: 184px !important;
}

#visit-customer-donut-card {
  min-height: 184px !important;
}

#revenue-column-card {
  min-height: 315px;
}

#revenue-radar-card {
  min-height: 336px;
}

#visiters-line-card {
  min-height: 315px;
}

#site-trafic-radar-card {
  min-height: 472px;
}