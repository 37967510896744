.da-logo {
  /* width: 100%; */
  height: 70px;
}
.logo-container {
  width: 100%;
  text-align: center;
  height: 65px;
  text-align: center;
  background: #fff;
}
