.ant-avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: 60%;
        margin-left: 2px !important;
    }
}