.ant-layout-header {
  background-color: @b-0;
  display: flex;
  align-items: center;
  z-index: 3;
  position: fixed;
  right: 0;
  left: 0;
  .@{class-prefix}-header-search {
    opacity: 0;
    visibility: hidden;
    transform: translate(-30px, 0);
    transition: 0.4s;

    &.@{class-prefix}-header-search-active {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }

    &-result {
      position: absolute;
      top: 100%;
      left: 0;
      right: 10px;
      background: @b-0;
      border-radius: 0 0 16px 16px;
      overflow: hidden;
      line-height: normal;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          height: auto;
          transition: 0.4s;
          font-size: 12px;
          line-height: normal;

          a {
            display: block;
            padding: 10px 40px;
            color: @b-100;
          }

          &:hover {
            background-color: @primary-4;

            a {
              color: @primary-1;
            }
          }
        }
      }
    }

    @media @md {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -70px;
      top: auto;

      .@{class-prefix}-header-search-result {
        top: calc(100% - 10px);
        border-radius: 16px;
        right: 0;
      }
    }
  }

  .@{class-prefix}-update-icon {
    min-width: 18px;
    border-radius: 9px;
  }

  .@{class-prefix}-mobile-sidebar-button {
    display: none;
  }

  .@{class-prefix}-header-left-text {
    &-item {
      line-height: 32px;

      .remix-icon {
        vertical-align: text-bottom;
      }
    }
  }

  @media screen and (max-width: 1380px) {
    .@{class-prefix}-header-left-text {
      &-item {
        line-height: normal;
      }
    }
  }

  @media @lg {
    .@{class-prefix}-mobile-sidebar-button {
      display: block;
    }

    .@{class-prefix}-header-left-text {
      display: none !important;
    }
  }

  @media @sm {
    margin: 16px 16px 32px;
    padding: 0 16px 0 8px;
  }
}

.@{class-prefix}-basket-dropdown-button:hover {
  .@{class-prefix}-basket-dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.@{class-prefix}-basket-dropdown {
  position: absolute;
  min-width: 330px;
  padding: 35px 16px 8px;
  top: 30px;
  right: -10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s;

  &.remove-cart {
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
  }

  &-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 358px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    bottom: 0;
    background: @b-0;
    border: 1px solid @b-40;
    border-radius: 8px;
  }

  > div {
    position: relative;
  }

  @media @sm {
    right: 0;
    left: 10%;
    transform: translate(-50%, 0px);
    min-width: 300px;
  }
}
