.ant-menu-horizontal .ant-menu-item svg {
  vertical-align: middle;
  margin-top: -2px;
  color: #fff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none;
}

.ant-menu-submenu-arrow {
  right: 0;
  
  &after {
    background-color: aliceblue !important;
  }
}

.toggel{
  display: block;
  width: 56px;
  background: #FFF;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 20px;
  transition: color 0.3s;
  margin-top: -5px;
}
.toggel-out{
  width: 80px;
}