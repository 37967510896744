.ml-auto {
  margin-left: unset !important;
  background-color: white;
  position: "relative";
}
.row {
  margin: 0 !important;
}
.dhir_dhir_emblem {
  position: absolute;
  margin-top: 4.6%;
  margin-left: 55%;
  width: 30%;
}
.login_bg {
  z-index: 0;
  position: absolute;
  height: 100%;
  width: 100%;
}
.login_hr {
  margin-top: -66px;
  margin-left: -25px;
  max-width: 111%;
  margin-right: -38px;
}

.login_bg1 {
  height: 100%;
  width: 48%;
  clip-path: polygon(
    100% 55%,
    36% 0%,
    0% 0%,
    0% 25%,
    0% 49%,
    0% 78%,
    0% 100%,
    38% 100%,
    61% 100%,
    100% 100%
  );
  position: absolute;
  z-index: 0;
}

.login_bg2 {
  height: 100%;
  clip-path: polygon(100% 65%, 100% 100%, 0 100%, 56% 0);
  position: absolute;
  left: 3%;
}
.full-width {
  width: 100%;
}
.login-card {
  width: 500px;
  margin-left: 55%;
  top: 10%;
  position: absolute;
}

@media screen and (max-width: 991px) {
  .login-card {
    width: 100vw;
    margin: 0;
    left: 4%;
  }
}
