.@{class-prefix}-content-main {
  margin: 90px 32px;
  min-height: calc(100vh - 173px);
  margin-left: 270px;

  @media @sm {
    margin: 24px 16px 0;
    min-height: calc(100vh - 144px);
    margin: 0 16px;
   
  }
}
@media @lg {
  .@{class-prefix}-content-main {
     margin-left: 32px;
  }
 
}