@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: @font-weight-extraLight;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: @font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: @font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: @font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: @font-weight-semiBold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: @font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("/assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: @font-weight-extraBold;
  font-style: normal;
}
