.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border:0px ;
  border-bottom:  1px solid #2d3436;
  border-radius: 0px
}
.ant-select{
  width: 100%;
}