.ant-modal-close-x {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 2px;
  }
}

.popup-modal {
  .ant-modal-body {
    padding-bottom: 24px;
  }
}
.ant-modal-content{
  border-radius: 1px;
}

.ant-modal-header.composeModal{
  padding : 0 !important
}
.ant-modal-title{
  color: #000;
}
.ant-modal-footer{
  border-top: 0px;
}

// .ant-modal-header{
//   border-bottom: 0px;
//   padding : 0
// }