.da-sidebar {
  height: 111vh;
  background-image: url(https://bbbemodule.web.app/sidenav-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #19a46c;
  position: fixed;
  z-index: 4;
}

.ant-menu {
  background: transparent;
}
.ant-menu-root{
  max-height: none!important;
}