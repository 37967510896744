.ant-dropdown-trigger.ant-dropdown-link {
  display: inline-flex;
  align-items: center;
  color: @primary-1;

  svg {
    margin-left: 10px;
  }
}


.ant-dropdown-menu {
  overflow-y: auto;
  padding: 8px 6px;
  border: 1px solid #dfe6e9;
  max-height: 400px;
}

// .ant-dropdown-open {
//   border: 1px solid #0a0a0a;
// }

// .ant-dropdown-menu-submenu-popup {
//   border: 1px solid #0a0a0a;
// }

// .ant-dropdown-menu-item {
//   &:hover {
//     background-color: @primary-4;
//   }
// }

// .ant-dropdown-menu-submenu-title {
//   &:hover {
//     background-color: @primary-4;
//   }
// }
